import React, { useState } from 'react';
import '../css/form.css';
import "../App.css";



const Host = 'https://containertriaxiod6l7yijh-videoapi.functions.fnc.fr-par.scw.cloud';
//const Host = 'http://localhost:5000';
const Form: React.FC = () => {
  const [email, setEmail] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [classe, setClasse] = useState('');
  const [code, setCode] = useState('');
  const [secret, setSecret] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [message, setMessage] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [showDownloadButton, setShowDownloadButton] = useState(false);
 
  const handleEmailSubmit = async (e: React.FormEvent) => {
   
    e.preventDefault();
    try {
      const response = await fetch(Host+'/api/send-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        setSecret(data.secret);
        setIsCodeSent(true);
        setMessage('Le code a été envoyé à votre adresse email. Veuillez le saisir ci-dessous.');

      } else {
        console.error('Failed to send code');
      }
    } catch (error) {
      console.error('Error sending code:', error);
    }
  };

  const handleCodeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!termsAccepted) {
      alert("Vous devez accepter les conditions d'utilisation.");
      return;
    }
    try {
      const response = await fetch(Host+'/api/validate-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ secret, token: code, email, nom, prenom, classe }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isValid) {
          setIsValidCode(true);
          setMessage("");
          setValidationMessage('Code validé ! Vous pouvez télécharger la vidéo.');
          setShowDownloadButton(true);
        } else {
          console.error('Invalid code');
          setValidationMessage('Code invalide. Veuillez réessayer.');
        }
      } else {
        console.error('Failed to validate code');
      }
    } catch (error) {
      console.error('Error validating code:', error);
    }
  };

  return (
    <div className="form-container">
       {message && <div className="message">{message}</div>}
      
      {!isCodeSent ? (
        <form onSubmit={handleEmailSubmit}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          

          <button type="submit">Envoyer le code</button>
        </form>
      ) :  !showDownloadButton ? (
        
      
        <form onSubmit={handleCodeSubmit}>
          <label htmlFor="nom">Votre Nom:</label>
          <input
            type="text"
            id="nom"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            required
          />
          <label htmlFor="prenom">Votre Prénom:</label>
          <input
            type="text"
            id="prenom"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            required
          />
          <label htmlFor="classe">Classe de votre ou de vos enfants:</label>
          <input
            type="text"
            id="classe"
            value={classe}
            onChange={(e) => setClasse(e.target.value)}
            required
          />
          <label htmlFor="code">Code recu par email:</label>
          <input
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
          <div className="terms">
            <input
              type="checkbox"
              id="terms"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <label htmlFor="terms">
              J'accepte les conditions d'utilisation. En téléchargeant ce fichier, je reconnais accepter les droits d'auteur et le droit à l'image conformément aux lois françaises.
              <a href="https://www.legifrance.gouv.fr/codes/id/LEGITEXT000006069414/" target="_blank" rel="noopener noreferrer"> [Code de la propriété intellectuelle]</a> et <a href="https://www.legifrance.gouv.fr/codes/id/LEGITEXT000006074069/" target="_blank" rel="noopener noreferrer">[Code civil - Droit à l'image]</a>.
              Je reconnais que toute diffusion en dehors du cadre familliale et sur le reseau internet est formellement interdite et peut induire des poursuites judiciaires conformement aux lois.
            </label>
          </div>
          <button type="submit">Valider le code</button>
        </form>
      ): (
       showDownloadButton && isValidCode && (
        <div className="download-section">
          <h3>{validationMessage}</h3>
            <p>Vidéo offerte par Julien Aurand</p>
            <img src="logo-julienAurand.jpeg" alt="Julien Aurand" className="logo" /><br /><br />
          <a href={Host+`/api/download-file?email=${encodeURIComponent(email)}&secret=${encodeURIComponent(secret)}&token=${encodeURIComponent(code)}&nom=${encodeURIComponent(nom)}&prenom=${encodeURIComponent(prenom)}&classe=${encodeURIComponent(classe)}`} download>
          <button>Télécharger la vidéo</button>
          </a>
        </div>
      )
      )}
    </div>
  );
};

export default Form;
